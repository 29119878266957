body {
    background-color: #fff;
}

.wrapper {
    display: flex;

    gap: 2rem;

    align-items: center;
    justify-items: center;

    margin-top: 2rem;

    width: 100%;
    justify-content: center;
}

.error {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        align-items: flex-start;
        max-width: 450px;
    }

    &__logo {
        margin-left: -1rem;
    }

    &__heading {
        font-weight: bold;
        color: rgba(66, 66, 66, 1);
    }

    &__paragraph {
        color: rgba(120, 120, 120, 1);
    }

    &__button {
        background: #ddf2f6;
        color: #165fd9;
        padding: 1rem;
        font-weight: bold;
        border-radius: 4px;
    }

    &__button:hover {
        text-decoration: none;
        background: #d2f0f6;
    }

    &__email {
        color: #165fd9;
    }
}
